<i18n>
{
  "de": {
    "buildingTitle": "Liegenschaft",
    "nameTitle": "Name",
    "descriptionTitle": "Beschreibung",
    "defaultTitle": "Vorlage",
    "hasDefaultCheckLabel": "Stammt aus Vorlage",
    "hasNoDefaultCheckLabel": "Stammt nicht aus Vorlage",
    "uvaluesTitle": "U-Werte",
    "uvaluesComponentLabel": "Komponente",
    "uvaluesOpaqueComponentLabel": "Dächer und Wände",
    "uvaluesWindowsComponentLabel": "Fenster",
    "investmentCostsTitle": "Investitionskosten",
    "investmentCostsComponentLabel": "Komponente",
    "investmentCostsInitialLabel": "Initialkosten",
    "investmentCostsPerM2Label": "Kosten pro m²"
  }
}
</i18n>

<template>
  <EditContainer :bind-form-state="formState" class="edit-renovation-standard" @container="onFormEvent">
    <FormRow v-if="model.building_id && building" :label="$t('buildingTitle')">
      <TextInput v-model="building.description" :edit="false" />
    </FormRow>
    <FormRow :label="$t('nameTitle')">
      <TextInput v-model="model.name" :edit="!this.renovationStandard" @validation="onValidation($event, 'name')" />
    </FormRow>
    <FormRow :label="$t('descriptionTitle')">
      <TextInput v-model="model.description" :edit="isEditing" :allow-empty="true" />
    </FormRow>
    <!-- uvalues -->
    <h3>{{ $t('uvaluesTitle') }}</h3>
    <DetailList has-header>
      <template #header>
        <span>{{ $t('uvaluesComponentLabel') }}</span>
        <span class="align-right"></span>
      </template>
      <li v-for="component_type in opaqueComponentTypes" :key="component_type">
        <span>{{ $t(`_envelopePlural.${component_type}`) }}</span>
        <div class="align-right">
          <NumericInput
            v-model="model.uvalues[component_type]"
            noFormatting
            optional
            float
            :min="0"
            :edit="isEditing"
            @validation="onValidation($event, component_type + '-uvalue')"
          />
        </div>
      </li>
    </DetailList>
    <!-- investment costs -->
    <h3>{{ $t('investmentCostsTitle') }}</h3>
    <DetailList has-header>
      <template #header>
        <span>{{ $t('investmentCostsComponentLabel') }}</span>
        <span class="align-right">{{ $t('investmentCostsInitialLabel') }}</span>
        <span class="align-right">{{ $t('investmentCostsPerM2Label') }}</span>
      </template>
      <li v-for="component_type in investmentCostsComponentTypes" :key="component_type">
        <span>{{ $t(`_envelope.${component_type}`) }}</span>
        <div class="align-right">
          <NumericInput
            v-model="model.investment_costs[component_type].initial"
            noFormatting
            optional
            float
            :min="0"
            :edit="isEditing"
            :units="'CHF'"
            @validation="onValidation($event, component_type + '-costs-initial')"
          />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.investment_costs[component_type].per_m2"
            noFormatting
            optional
            float
            :min="0"
            :edit="isEditing"
            :units="'CHF'"
            @validation="onValidation($event, component_type + '-costs-pre-m2')"
          />
        </div>
      </li>
    </DetailList>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EditContainer,
    FormRow,
    TextInput,
    NumericInput,
    DetailList,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
    },
    renovationStandard: {
      type: Object,
    },
  },

  computed: {
    // mixin override
    allowEdit() {
      return this.renovationStandard ? !this.renovationStandard.default : true // prevent default edits
    },

    opaqueComponentTypes() {
      return ['walls', 'windows', 'roof', 'basement']
    },

    investmentCostsComponentTypes() {
      return ['windows', 'roof_flat', 'roof_gable', 'walls', 'basement']
    },
  },

  watch: {
    renovationStandard: function () {
      this.resetModel()
    },
  },

  created() {
    if (!this.renovationStandard) {
      // Start editing immediately when adding a new grid
      this.onFormEvent('edit')
    }
    this.resetModel()
  },

  methods: {
    ...mapActions({
      refreshBuildingDetails: 'building/refreshDetailsById',
      addRenovationStandard: 'portfolio/addRenovationStandard',
      updateRenovationStandard: 'portfolio/updateRenovationStandard',
    }),

    resetModel() {
      if (this.renovationStandard) {
        this.model = {
          portfolio_id: this.renovationStandard.portfolio_id,
          name: this.renovationStandard.name,
          description: this.renovationStandard.description,
          uvalues: JSON.parse(JSON.stringify(this.renovationStandard.uvalues)),
          investment_costs: JSON.parse(JSON.stringify(this.renovationStandard.investment_costs)),
          building_id: this.renovationStandard.building_id,
        }
      } else {
        this.model = {
          portfolio_id: this.portfolio.id,
          name: null,
          description: null,
          uvalues: { roof: 0, basement: 0, walls: 0, windows: 0 }, // TODO: use defaults
          investment_costs: {},
          building_id: this.building ? this.building.id : null,
        }
        this.investmentCostsComponentTypes.forEach((ct) => {
          this.model.investment_costs[ct] = { initial: 0, per_m2: 0 } // TODO: use defaults
        })
      }
    },

    async saveHandler() {
      try {
        this.error = null
        if (this.renovationStandard) {
          if (!this.renovationStandard.default) {
            await this.updateRenovationStandard({ id: this.renovationStandard.id, renovationStandard: this.model })
            if (this.building && this.building.id) {
              await this.refreshBuildingDetails(this.building.id)
            }
          }
        } else {
          await this.addRenovationStandard(this.model)
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
  },
}
</script>

<style>
.edit-renovation-standard .align-right {
  text-align: right;
  justify-content: flex-end;
}
</style>
