<!--
Modal Wrapper for EditRenovationStandard
-->
<i18n>
{
  "de": {
    "editTitle": "Renovationsstandard bearbeiten",
    "createTitle": "Renovationsstandard erstellen",
    "descriptionTitle": "Bezeichnung",
    "endEnergyTitle": "Endenergiebedarf"
  }
}
</i18n>

<template>
  <Modal wide class="edit-renovation-standard-modal" @close="$emit('close', $event)">
    <div v-if="error">
      <p>{{ error }}</p>
    </div>
    <template #header>
      <h2 v-if="!renovationStandard">{{ $t('createTitle') }}</h2>
      <h2 v-else>{{ $t('editTitle') }}</h2>
    </template>
    <EditRenovationStandard
      :portfolio="portfolio"
      :renovation-standard="renovationStandard"
      :building="building"
      @save="onSave"
      @cancel="onCancel"
      @error="onError"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import EditRenovationStandard from '@/components/settings/renovation_standards/EditRenovationStandard.vue'

export default {
  components: {
    Modal,
    EditRenovationStandard,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    renovationStandard: {
      // edit existing renovation standard
      type: Object,
    },
    building: {
      type: Object,
    },
  },

  data() {
    return {
      error: null,
    }
  },

  methods: {
    onCancel() {
      if (this.renovationStandard === null) {
        // Close dialog if adding new is canceled
        this.$emit('close')
      }
    },

    onSave() {
      if (this.renovationStandard === null) {
        // Close dialog if adding new is saved
        if (!this.error) {
          this.$emit('close')
        }
      }
    },

    onError(error) {
      this.error = error
    },
  },
}
</script>
